.tabs-card-resumes{

    display: none;
}
.main-resume-container > .MuiPaper-elevation{
    background-color: #ffffff00;
    border: none;
}

.main-resume-container > .MuiPaper-elevation .MuiCardHeader-root{
    background-color: rgba(22, 119, 255, 0.08);
    border: solid 0.25px #eee;
}

.main-resume-container > .MuiPaper-elevation > form >.MuiBox-root{
    background-color: #ffffff00;
    border: solid 0.25px #eee;
}
.tabs-card-resume-container > .MuiStack-root {
    padding: 0px !important;
}
.MuiStack-root:has(.tabs-card-resume-container){
    margin-right: 0px !important;
}
.tabs-card-resume-container {
    position: relative;
}
.tabs-card-resume-container > .MuiStack-root > .MuiStack-root > .MuiGrid-root{
    width: 60vw !important;
}
.add-button-resume-section{
    position: absolute;
    z-index: 9;
    right: 119px;
    top: 25px;   
}
.add-button-resume-section-without-data{
    position: absolute;
    z-index: 9;
    right: 19px;
    top: 25px;   
}
.user-profile-placeholder{
    width:63px;
    height: 55px;
}
.user-profile-placeholder>img{
   max-width: 100%;
   max-height: 100%;
   border-radius: 35px;
}
.form-vacancy-container>.MuiPaper-root>.MuiCardHeader-root{

    background-color: rgba(22, 119, 255, 0.08);
    border: solid 0.25px #eee;
}
.form-vacancy-container>.MuiPaper-root{
    margin-top: 15px;
}
.permission-card>.MuiPaper-root>.MuiGrid-root{
    border: solid 0.25px #eee;
    padding: 26px;
}
